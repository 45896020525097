import styles from "./Footer.module.scss";
import telegram from "src/modules/landing/assets/img/png/telegram.png";
import twitter from "src/modules/landing/assets/img/png/twitter.png";
import instagram from "src/modules/landing/assets/img/png/instagram.png";
import youtube from "src/modules/landing/assets/img/png/youtube.png";
import tiktok from "src/modules/landing/assets/img/png/tiktok.png";

export const Footer = () => {
	return (
		<div className={styles.content}>
			<div className={styles.mainContent}>
				<div className={styles.btns}>
					<div onClick={()=>{
						window.open("https://jup.ag/swap/SOL-2Zaq3WyDJj2ZkMcuqUiTBfAXCjqZtqihxcwUdwv9dyHA");
					}}>buy $pepe</div>
					<div style={{opacity:"0.7", pointerEvents:"none"}}>whitepaper</div>
					<div onClick={()=>{
						window.open("https://nextgenpepe.com/");
					}}>main website</div>
				</div>
				<div className={styles.icons}>
					<img src={telegram} alt="" onClick={()=>{
						window.open("https://t.me/sigmaversenews");
					}}/>
					<img src={twitter} alt="" onClick={()=>{
						window.open("https://x.com/sigmaversegame");
					}}/>
					<img src={instagram} alt="" onClick={()=>{
						window.open("https://instagram.com/sigmaversegame");
					}}/>
					<img src={tiktok} alt="" onClick={()=>{
						window.open("https://tiktok.com/sigmaversegame");
					}}/>
					<img src={youtube} alt="" onClick={()=>{
						window.open("https://youtube.com/@sigmaversegame");
					}}/>
				</div>
			</div>
			<div className={styles.bottom}>
				<div className={styles.bottomLeft}>
                    <div>Contact us: nextgenpepe@gmail.com</div>
                    <div>©Pepexverse 2024</div>
                </div>
				<div className={styles.bottomRight}>
                    <div onClick={()=>{
						window.open("https://nextgenpepe.com/privacy-policy")
					}}>Privacy Policy</div>
                    <div onClick={()=>{
						window.open("https://nextgenpepe.com/terms-of-service");
					}}>Terms of Use</div>
                    <div onClick={()=>{
						window.open("https://nextgenpepe.com/terms-of-service");
					}}>Legal Disclaimer</div>
                </div>
			</div>
		</div>
	);
};
