import {Textfit} from "react-textfit";
import styles from "./MainBanner.module.scss";
import girl from "src/modules/landing/assets/img/png/darkGirl.png";
import girlHover from "src/modules/landing/assets/img/png/darkGirlHover.png";
import player1 from "src/modules/landing/assets/img/png/playerHuge1.png";
import player2 from "src/modules/landing/assets/img/png/playerHuge2.png";
import player3 from "src/modules/landing/assets/img/png/playerHuge3.png";
import arrow from "src/modules/landing/assets/img/svg/arrowDiagonal.svg";
import reward from "src/modules/landing/assets/img/png/reward.png";
import crystal from "src/modules/landing/assets/img/png/crystal.png";
import arrowDown from "src/modules/landing/assets/img/svg/arrowDown.svg";
import cl from "classnames";
import {useState} from "react";

export const MainBanner = () => {
	const [isHover, setIsHover] = useState<boolean>(false);

	return (
		<div className={styles.content}>
			<div className={styles.mainContent}>
				<div
					className={styles.banner}
					onMouseEnter={() => setIsHover(true)}
					onMouseLeave={() => setIsHover(false)}
					onClick={()=>{ window.open("https://t.me/pepexversebot/play");}}
				>
					<img src={arrow} alt="" className={styles.bannerArrow} />
					<div className={styles.texts}>
						<Textfit max={78} min={16} className={styles.title}>
							Sigmaverse
						</Textfit>
						<div className={styles.subTitle}></div>
					</div>
					<img
						src={isHover ? girlHover : girl}
						alt=""
						className={styles.girl}
					/>
				</div>
				<div className={styles.blocks}>
					<div className={cl(styles.block, styles.block1)}>
						<div>FIRST NFT DROP</div>
						<div>Coming soon</div>
						<img src={player1} alt="" className={styles.player1} />
						<img src={player2} alt="" className={styles.player2} />
						<img src={player3} alt="" className={styles.player3} />
						<img src={arrow} alt="" className={styles.blockArrow} />
					</div>
					<div className={cl(styles.block, styles.block2)} onClick={()=>{
						window.open("https://t.me/pepexversebot/play");
					}}>
						<div>play and earn</div>
						<div>$200 SOL weekly</div>
						<img src={reward} alt="" className={styles.reward1} />
						<img src={reward} alt="" className={styles.reward2} />
						<img src={arrow} alt="" className={styles.blockArrow} />
					</div>
					<div className={cl(styles.block, styles.block3)}>
						<div>Sigma jackpot</div>
						<div>Coming soon</div>
						<img src={crystal} alt="" className={styles.crystal1} />
						<img src={crystal} alt="" className={styles.crystal2} />
						<img src={crystal} alt="" className={styles.crystal3} />
						<img src={arrow} alt="" className={styles.blockArrow} />
					</div>
				</div>
			</div>
			<img src={arrowDown} alt="" className={styles.arrowDown} />
		</div>
	);
};
